import { FC } from 'react'
import { observer } from 'mobx-react'
import { TabButton } from '@components/atoms/TabButton'
import styles from './index.module.scss'

type NavItem = {
  label: string
  href: string
  isActive: boolean
  badgeContent?: string
  onClick?: () => void
  componentType?: 'a' | 'button'
}

type Props = {
  items: NavItem[]
}

export const AttackListTabNavBar: FC<Props> = observer(({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <TabButton
          key={item.label}
          isCurrent={item.isActive}
          href={item.href}
          badgeContent={item.badgeContent}
          onClick={item.onClick}
          componentType={item.componentType}
        >
          {item.label}
        </TabButton>
      ))}
    </div>
  )
})
