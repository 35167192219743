import { FC } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { AttackListDesktopHeader } from '../AttackListDesktopHeader'
import { AttackListMobileHeader } from '../AttackListMobileHeader'

type Props = {
  shouldShowMenus: boolean
}

export const AttackListHeader: FC<Props> = ({ shouldShowMenus }) => {
  return (
    <header
      className={classNames(styles.container, {
        [styles.containerWithMenu]: shouldShowMenus,
      })}
    >
      <div className={styles.desktop}>
        <AttackListDesktopHeader shouldShowMenus={shouldShowMenus} />
      </div>
      <div className={styles.mobile}>
        <AttackListMobileHeader shouldShowMenus={shouldShowMenus} />
      </div>
    </header>
  )
}
