import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import { Avatar } from '@components/atoms/Avatar'
import CheckIconSvg from '@/public/images/common/check_FILL0_icon_v2.svg'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  // TODO: checkbox は未実装
  type: 'default' | 'withAvatar' | 'withLeftIcon' | 'withRightIcon' | 'checkbox'
  avatarSrc?: string
  icon?: React.SVGProps<SVGElement>
  isSelected?: boolean
  text: string
  subText?: string
}

export const MenuItemContent: FC<PropsWithChildren<Props>> = observer(
  ({ type, avatarSrc, icon, isSelected = false, text, subText = '' }) => {
    const className = classNames(
      styles.container,
      {
        [styles.default]: type === 'default',
        [styles.withAvatar]: type === 'withAvatar',
        [styles.withLeftIcon]: type === 'withLeftIcon',
        [styles.withRightIcon]: type === 'withRightIcon',
      },
      {
        [styles.large]: subText,
      }
    )

    const shouldShowCheckIcon = (type === 'default' || type === 'withAvatar') && isSelected

    return (
      <div className={className}>
        <div className={styles.left}>
          {type === 'withAvatar' && (
            <div className={styles.left__avatar}>
              <Avatar src={avatarSrc} />
            </div>
          )}
          {type === 'withLeftIcon' && <div className={styles.left__iconSvg}>{icon}</div>}

          <div className={styles.left__labels}>
            <div className={styles.left__labels__main}>{text}</div>
            {subText && <div className={styles.left__labels__sub}>{subText}</div>}
          </div>
        </div>
        <div className={styles.right}>
          {shouldShowCheckIcon && (
            <div className={styles.right__checkSvg}>
              <CheckIconSvg className={styles.checkIconSvg} viewBox='0 0 20 20' />
            </div>
          )}
          {type === 'withRightIcon' && <div className={styles.right__iconSvg}>{icon}</div>}
        </div>
      </div>
    )
  }
)
