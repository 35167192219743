import { FC, useState } from 'react'
import { observer } from 'mobx-react'
import { OutSideOverlay } from '@/components/atoms/OutSideOverlay'
import { AttackListPickerTriggerButton } from '@/components/molecules/AttackListPickerTriggerButton'
import { AttackListPickerDropdown } from '@/components/molecules/AttackListPickerDropdown'
import styles from './index.module.scss'

export const AttackListPicker: FC = observer(() => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropDown = () => {
    setIsOpen(!isOpen)
  }

  return (
    <div className={styles.container}>
      <AttackListPickerTriggerButton isOpen={isOpen} open={toggleDropDown} />
      {isOpen && (
        <div className={styles.dropDown}>
          <AttackListPickerDropdown toggleDropDown={toggleDropDown} />
        </div>
      )}
      <OutSideOverlay onClick={toggleDropDown} isOpen={isOpen} backGroundColor='gray' />
    </div>
  )
})
