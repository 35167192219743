import Link from 'next/link'
import { FC } from 'react'
import { BlackLogo } from '@components/atoms/BlackLogo'
import { IndexTabNavBar } from '@/components/molecules/IndexTabNavBar'
import styles from './index.module.scss'
import { GlobalDesktopHeaderRight } from '../GlobalDesktopHeaderRight'
import { AttackListPicker } from '../AttackListPicker'

type Props = {
  shouldShowMenus: boolean
}

export const AttackListDesktopHeader: FC<Props> = ({ shouldShowMenus }) => {
  return (
    <div className={styles.container}>
      <div className={styles.upper}>
        <div className={styles.upperLeft}>
          <Link href='/'>
            <a className={styles.logo}>
              <BlackLogo />
            </a>
          </Link>
          {shouldShowMenus && <AttackListPicker />}
        </div>
        <GlobalDesktopHeaderRight />
      </div>
      <div className={shouldShowMenus ? styles.lowerWithMenu : styles.lower}>
        <IndexTabNavBar />
      </div>
    </div>
  )
}
