import { FC, MouseEventHandler, KeyboardEventHandler, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import { sanitizeUrl } from '@/utils'
import Link from 'next/link'
import { MenuItemContent } from '@components/atoms/MenuItemContent'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  // TODO: checkbox は未実装
  type: 'default' | 'withAvatar' | 'withLeftIcon' | 'withRightIcon' | 'checkbox'
  componentType: 'button' | 'a'
  href?: string
  anchorTarget?: '_self' | '_blank'
  onClick?: MouseEventHandler<HTMLDivElement>
  onKeyPress?: KeyboardEventHandler<HTMLDivElement>
  avatarSrc?: string
  icon?: React.SVGProps<SVGElement>
  isSelected?: boolean
  isActive?: boolean
  text: string
  subText?: string
}

export const MenuItem: FC<PropsWithChildren<Props>> = observer(
  ({
    type,
    componentType,
    href,
    anchorTarget = '_self',
    onClick,
    onKeyPress,
    avatarSrc,
    icon,
    isSelected = false,
    isActive = false,
    text,
    subText,
  }) => {
    const content = (
      <MenuItemContent
        type={type}
        avatarSrc={avatarSrc}
        icon={icon}
        isSelected={isSelected}
        text={text}
        subText={subText}
      />
    )

    const className = classNames(
      styles.container,
      {
        [styles.clickable]: !isSelected,
      },
      {
        [styles.active]: isActive,
      }
    )

    if (componentType === 'a') {
      return (
        <Link href={sanitizeUrl(href)}>
          <a className={className} target={anchorTarget}>
            {content}
          </a>
        </Link>
      )
    }

    return (
      <div className={className} role='button' tabIndex={0} onClick={onClick} onKeyPress={onKeyPress}>
        {content}
      </div>
    )
  }
)
