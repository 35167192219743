import { FC } from 'react'
import { LoadingIndicator } from '@components/atoms/LoadingIndicator'
import { BlackLogo } from '@components/atoms/BlackLogo'
import styles from './index.module.scss'

export const GlobalLoadingIndicator: FC = () => {
  return (
    <div className={styles.container}>
      <LoadingIndicator size='large' />
      <div className={styles.logo}>
        <BlackLogo />
      </div>
    </div>
  )
}
