import { FC } from 'react'
import { observer } from 'mobx-react'
import { Menu } from '@components/atoms/Menu'
import { MenuDivider } from '@components/atoms/MenuDivider'
import { useRouter } from 'next/router'
import { getIsActive, useStores } from '@/utils'
import { MenuItem } from '@/components/atoms/MenuItem'
import { DbPlan } from '@/types'

type Props = {
  toggleDropDown: () => void
}

export const AttackListPickerDropdown: FC<Props> = observer(({ toggleDropDown }) => {
  const router = useRouter()
  const { viewer } = useStores()

  return (
    <Menu size='wide' toggleDropDown={toggleDropDown}>
      {viewer.viewer?.dbPlan === DbPlan.BASIC_PLAN && (
        <>
          <MenuItem
            key={viewer.viewer?.username}
            type='default'
            componentType='a'
            href={`/${viewer.viewer?.username}`}
            text={viewer.viewer?.name}
            isSelected={getIsActive([`${viewer.viewer?.username}`], router)}
          />
          {viewer.viewer?.userOwners.length > 0 && <MenuDivider />}
        </>
      )}
      {viewer.viewer?.userOwners?.map((userOwner, index) => (
        <>
          <MenuItem
            key={userOwner?.owner?.username}
            type='default'
            componentType='a'
            href={`/${userOwner?.owner?.username}`}
            text={userOwner?.owner?.name}
            isSelected={getIsActive([`${userOwner?.owner?.username}`], router)}
          />
          {viewer.viewer?.userOwners.length !== index + 1 && <MenuDivider />}
        </>
      ))}
    </Menu>
  )
})
