import { FC } from 'react'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { GlobalLoadingIndicator } from '@components/organisms/GlobalLoadingIndicator'

type Props = {
  isLoadIndicator?: boolean
}

export const SessionPageTemplate: FC<Props> = observer(({ children, isLoadIndicator = false }) => {
  const { viewer } = useStores()
  if (viewer.isInitialized && !isLoadIndicator) {
    return <div>{children}</div>
  }
  return <GlobalLoadingIndicator />
})
