import { FC } from 'react'
import { PageTransition } from 'next-page-transitions'
import { useRouter } from 'next/router'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { ToastManager } from '@/components/organisms/ToastManager'
import { AttackListHeader } from '@/components/organisms/AttackListHeader'
import { LeadToDbPaymentModal } from '@/components/organisms/LeadToDbPaymentModal'
import { DbPaymentModal } from '@/components/organisms/DbPaymentModal'
import { SessionPageTemplate } from '../SessionPageTemplate'
import styles from './index.module.scss'

type Props = {
  targetUsername?: string
  isLoadIndicator?: boolean
}

/**
 * アタックリスト全ページで利用するテンプレート
 */
export const AttackListPageTemplate: FC<Props> = observer(
  ({ children, targetUsername = null, isLoadIndicator = false }) => {
    const router = useRouter()
    const { viewer } = useStores()

    const getShouldShowMenus = (): boolean => {
      // 初期化されてない場合は表示しない
      if (!viewer.isInitialized || !viewer.isSignedIn) {
        return false
      }

      // 初期化されている場合
      if (!targetUsername) {
        // targetUsername の指定がなければ表示しない
        return false
      }

      return true
    }

    return (
      <SessionPageTemplate isLoadIndicator={isLoadIndicator}>
        <div className={styles.container}>
          <AttackListHeader shouldShowMenus={getShouldShowMenus()} />
          <main className={styles.main}>
            <PageTransition timeout={300} classNames='page-transition'>
              <PageTransitionInner key={router.route}>
                <div className={styles.container}>{children}</div>
              </PageTransitionInner>
            </PageTransition>
          </main>
          <ToastManager />
        </div>
        <LeadToDbPaymentModal />
        <DbPaymentModal />
      </SessionPageTemplate>
    )
  }
)

// PageTransitionに直でHTMLとなる要素を渡すとエラーを吐くので、そのエラーを潰す用に噛ませるComponent
const PageTransitionInner: FC = ({ children }) => {
  return <>{children}</>
}
