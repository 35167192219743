import { FC } from 'react'
import classNames from 'classnames'
import { observer } from 'mobx-react'
import { useStores } from '@/utils'
import { Button2 } from '@/components/atoms/Button2'
import { useTranslation } from 'next-i18next'
import { LeadToDbPaymentTable } from '@/components/molecules/LeadToDbPaymentTable'
import { BlackLogo } from '@/components/atoms/BlackLogo'
import styles from './index.module.scss'

type Props = {
  onClose?: () => void
}

export const LeadToDbPaymentModal: FC<Props> = observer(({ onClose }) => {
  const { t } = useTranslation(['global'])
  const { ui } = useStores()

  const closeModal = () => {
    ui.toggleLeadToDbPaymentModalOpen()

    // コールバックがあれば実行
    if (onClose) {
      onClose()
    }
  }

  return (
    <div
      className={classNames(styles.modal, {
        [styles.activeModal]: ui.isLeadToDbPaymentModalOpen,
      })}
    >
      <div className={styles.container}>
        <div className={styles.inner}>
          <div className={styles.content}>
            <div className={styles.closeButton} onClick={closeModal} aria-hidden='true' role='button'>
              <img src='/images/common/close.svg' alt='Close' className='img-fluid' />
            </div>
            <div className={styles.header}>
              <div className={styles.logo}>
                <BlackLogo />
              </div>
            </div>
            <div className={styles.body}>
              <h2 className={styles.heading}>{t('閲覧には TOSHIKA DB のサブスクリプションが必要です。')}</h2>
              <div className={styles.table}>
                <LeadToDbPaymentTable />
              </div>
              <div className={styles.button}>
                <Button2
                  componentType='button'
                  hierarchy='primary'
                  size='xl'
                  onClick={() => {
                    ui.toggleLeadToDbPaymentModalOpen()
                    ui.toggleDbPaymentModal()
                  }}
                >
                  {t('サブスクリプションを始める')}
                </Button2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
