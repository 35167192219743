import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import CheckSvg from 'public/images/common/check_v2.svg'
import styles from './index.module.scss'

export const LeadToDbPaymentTable: FC = () => {
  const { t } = useTranslation(['global'])
  return (
    <div className={styles.container}>
      <table className={styles.content}>
        <thead className={styles.tHeader}>
          <tr className={styles.tdGroup}>
            <th>{t('機能')}</th>
            <th>{t('無料プラン')}</th>
            <th>{t('有料プラン')}</th>
          </tr>
        </thead>
        <tbody className={styles.tBody}>
          <tr className={styles.tdGroup}>
            <td>{t('投資家管理ツールの利用')}</td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
          <tr className={styles.tdGroup}>
            <td>{t('VC のみの検索・閲覧')}</td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
          <tr className={styles.tdGroup}>
            <td>{t('VC、CVC、上場、非上場、ファミリーオフィス、その他の投資会社の検索・閲覧')}</td>
            <td>
              <div className={styles.invalid}>{t('×')}</div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
          <tr className={styles.tdGroup}>
            <td>{t('起業家による口コミ')}</td>
            <td>
              <div className={styles.invalid}>{t('×')}</div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
          <tr className={styles.tdGroup}>
            <td>{t('VC のみの外部リンク')}</td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
          <tr className={styles.tdGroup}>
            <td>{t('VC、CVC、上場、非上場、ファミリーオフィス、その他の投資会社の外部リンク')}</td>
            <td>
              <div className={styles.invalid}>{t('×')}</div>
            </td>
            <td>
              <div className={styles.icon}>
                <CheckSvg viewBox='0 0 24 24' />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
