import { FC, PropsWithChildren } from 'react'
import classNames from 'classnames'
import styles from './index.module.scss'

// size が md
// color が gray | primary
// type が pill color
// icon なし
// の場合のバッジを一旦作成

type Props = {
  size?: 'md'
  color?: 'gray' | 'primary'
  type?: 'pill color'
  hasIcon?: boolean
}

export const Badge: FC<PropsWithChildren<Props>> = ({
  size = 'md',
  color = 'gray',
  // type = 'pill color',
  // hasIcon = false,
  children,
}) => {
  return (
    <div
      className={classNames(
        styles.container,
        {
          [styles.md]: size === 'md',
        },
        {
          [styles.gray]: color === 'gray',
          [styles.primary]: color === 'primary',
        }
      )}
    >
      {children}
    </div>
  )
}
