import { FC } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { getQueryValue, useStores } from '@/utils'
import Svg from '@/public/images/common/expand_more_FILL0_icon_v2.svg'
import styles from './index.module.scss'

type Props = {
  isOpen: boolean
  open: () => void
}

export const AttackListPickerTriggerButton: FC<Props> = observer(({ isOpen, open }) => {
  const router = useRouter()
  const { viewer } = useStores()

  const targetUsername = getQueryValue('target_username', router)
  const attackListOwner = viewer.viewer?.getAttackListOwner(targetUsername)

  const className = classNames(styles.container, {
    [styles.open]: isOpen,
  })

  return (
    <button type='button' onClick={open} onKeyPress={open} className={className}>
      <div className={styles.name}>{attackListOwner?.name}</div>
      <div className={styles.icon}>
        <Svg viewBox='0 0 20 20' />
      </div>
    </button>
  )
})
