import Link from 'next/link'
import { FC, PropsWithChildren } from 'react'
import { sanitizeUrl } from '@/utils'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import styles from './index.module.scss'
import { Badge } from '../Badge'

type Props = {
  isCurrent: boolean
  // Figma 上のプロパティを一応追加しておくが、現状使用されてるものだけデザインを追加
  type?: 'underline'
  size?: 'md'
  fullWidth?: boolean
  componentType?: 'a' | 'button'
  onClick?: () => void
  href: string
  badgeContent?: string
}

export const TabButton: FC<PropsWithChildren<Props>> = observer(
  ({
    isCurrent,
    type = 'underline',
    size = 'md',
    fullWidth = false,
    componentType = 'a',
    onClick,
    href,
    badgeContent = null,
    children,
  }) => {
    const className = classNames(
      styles.container,
      { [styles.md]: size === 'md' },
      { [styles.underline]: type === 'underline' },
      { [styles.fullWidth]: fullWidth },
      { [styles.current]: isCurrent }
    )

    if (componentType === 'button') {
      return (
        <button className={className} onClick={onClick} type='button'>
          <div className={styles.content}>{children}</div>
          {badgeContent && (
            <div className={styles.badge}>
              <Badge>{badgeContent}</Badge>
            </div>
          )}
        </button>
      )
    }
    return (
      <Link href={sanitizeUrl(href)}>
        <a className={className}>
          <div className={styles.content}>{children}</div>
          {badgeContent && (
            <div className={styles.badge}>
              <Badge>{badgeContent}</Badge>
            </div>
          )}
        </a>
      </Link>
    )
  }
)
