import { FC, PropsWithChildren } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import styles from './index.module.scss'

type Props = {
  size: 'normal' | 'wide'
  toggleDropDown?: () => void
}

export const Menu: FC<PropsWithChildren<Props>> = observer(({ size, toggleDropDown, children }) => {
  const className = classNames(styles.container, {
    [styles.wide]: size === 'wide',
  })
  return (
    <div className={className} role='button' tabIndex={0} onClick={toggleDropDown} onKeyPress={toggleDropDown}>
      {children}
    </div>
  )
})
