import { FC } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { getIsActive, getQueryValue, useStores } from '@/utils'
import { AttackListTabNavBar } from '@/components/organisms/AttackListTabNavBar'

export const IndexTabNavBar: FC = () => {
  const { t } = useTranslation(['global'])
  const { viewer, ui } = useStores()
  const router = useRouter()
  const targetUsername = getQueryValue('target_username', router)

  const baseItems = [
    {
      label: t('ダッシュボード'),
      href: `/${targetUsername}`,
      onClick: !targetUsername
        ? () => {
            ui.toggleLeadToContactFormModal()
          }
        : undefined,
      componentType: targetUsername ? 'a' : 'button',
    },
    {
      label: t('アタックリスト'),
      href: `/${targetUsername}/attack-lists`,
      onClick: !targetUsername
        ? () => {
            ui.toggleLeadToContactFormModal()
          }
        : undefined,
      componentType: targetUsername ? 'a' : 'button',
    },
  ]

  const corporateInvestorItems = viewer.viewer.isVC
    ? [
        {
          label: t('LP 投資家一覧'),
          href: targetUsername ? `/${targetUsername}/corporate-investors` : `/corporate-investors`,
        },
      ]
    : [
        {
          label: t('投資会社一覧'),
          href: targetUsername ? `/${targetUsername}/corporate-investors` : `/corporate-investors`,
        },
      ]

  const additionalItem = [
    {
      label: t('口コミ一覧'),
      href: `/${targetUsername}/reviews`,
      onClick: () => {
        if (!targetUsername) {
          ui.toggleLeadToContactFormModal()
        } else if (viewer.isSignedIn && !viewer.viewer.hasValidSubscription) {
          ui.toggleLeadToDbPaymentModalOpen()
        }
      },
      componentType: viewer.isSignedIn && viewer.viewer.hasValidSubscription ? 'a' : 'button',
    },
    {
      label: t('外部リンク一覧'),
      href: targetUsername ? `/${targetUsername}/links` : `/links`,
    },
  ]

  const adminItem = viewer.viewer.settingableAttackList(targetUsername)
    ? [
        {
          label: t('設定'),
          href: `/${targetUsername}/settings`,
        },
      ]
    : []

  const items = [...baseItems, ...corporateInvestorItems, ...additionalItem, ...adminItem].map((item) => ({
    ...item,
    isActive: getIsActive([item.href], router, true, false),
  }))

  return <AttackListTabNavBar items={items} />
}
