import { FC } from 'react'
import LogoSvg from '@/public/images/common/logo/toshika_db_logo_b.svg'
import styles from './index.module.scss'

export const BlackLogo: FC = () => {
  return (
    <span className={styles.container}>
      <div className={styles.desktop}>
        <LogoSvg />
      </div>
      <div className={styles.mobile}>
        <LogoSvg />
      </div>
    </span>
  )
}
